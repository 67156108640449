import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDealListAction,
    updateDealAction,
    discardDealChangesAction,
    updateTransferDealAction,
} from '../../redux/actions/transferDealAction';
import { isLoadingAction } from '../../redux/actions/utilitiesAction';
import { token, isLoading, transferDealSelector } from '../../redux/selectors';

import View from './view';

export default () => {
    const dispatch = useDispatch();
    const transferDeal = useSelector((state) => transferDealSelector(state, 'transferDealReducer'));
    const responseToken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));
    const [isEditable, setIsEditable] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [newData, setNewData] = useState([]);
    const [selectedSP, setSelectedSP] = useState({});
    const [search, setSearch] = useState('');

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleEdit = (isEditable) => {
        setIsEditable(isEditable);
    };

    useEffect(() => {
        if (responseToken && selectedMonth && selectedYear) {
            dispatch(isLoadingAction(true));
            dispatch(getDealListAction(selectedMonth + selectedYear));
        }
    }, [responseToken, selectedMonth, selectedYear]);

    const onDiscardChanges = () => {
        dispatch(discardDealChangesAction());
        setIsEditable(false);
    };

    const onRowEdit = (newValue) => {
        if ('SANO1' in newValue) {
            setSelectedSP({ ...selectedSP, [newValue.SANO1]: true });
        }
        setNewData((prevData) => {
            const index = prevData.findIndex((item) => item._id === newValue._id);
            if (index !== -1) {
                const updatedData = [...prevData];
                updatedData[index] = { ...updatedData[index], ...newValue };
                return updatedData;
            } else {
                return [...prevData, newValue];
            }
        });
        dispatch(updateDealAction(newValue));
    };
    const handleDateChange = (value) => {
        if (value.name === 'month') {
            setSelectedMonth(value.value);
        } else {
            setSelectedYear(value.value);
        }
    };

    const onSaveChanges = () => {
        dispatch(isLoadingAction(true));
        dispatch(
            updateTransferDealAction({
                data: { deals: newData },
                date: selectedMonth + selectedYear,
            })
        );
        setNewData([]);
        setIsEditable(false);
    };

    const fileredData = useMemo(() => {
        if (search) {
            const dealList = transferDeal?.deal.filter((deal) => {
                return deal?.FIWI.includes(search);
            });
            return {
                ...transferDeal,
                deal: dealList,
            }
        }
        return transferDeal;
    }, [search, transferDeal]);

    return (
        <View
            isLoadingResponse={isLoadingResponse}
            onRowEdit={onRowEdit}
            onEdit={handleEdit}
            isEditable={isEditable}
            data={fileredData}
            handleDateChange={handleDateChange}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            onDiscardChanges={onDiscardChanges}
            selectedSP={selectedSP}
            onSaveChanges={onSaveChanges}
            onSearch={handleSearch}
        />
    );
};
