import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import {
    Paper,
    Grid,
    Typography,
    TextField,
    Tabs,
    Tab,
    Fab,
    Table,
    TableBody,
    TableHead,
    IconButton,
    TableRow,
    TableCell,
    Button,
} from '@material-ui/core';
import { Delete, Edit, Save } from '@material-ui/icons';

const TabPanel = (props) => {
    const { arrayData, value, index, handleChangeTextField } = props;

    return (
        <div>
            {value === index && (
                <SubDealsCard
                    arrayData={arrayData}
                    handleChangeTextField={handleChangeTextField}
                    indexKeyObject={index}
                />
            )}
        </div>
    );
};

const SubDealsCard = (props) => {
    const { arrayData, handleChangeTextField, indexKeyObject } = props;
    const classes = useStyles();

    // State to track whether editing is enabled for each row
    const [editingRows, setEditingRows] = useState([]);

    const toggleEditing = (index) => {
        setEditingRows((prevEditingRows) => {
            const newEditingRows = [...prevEditingRows];
            newEditingRows[index] = !newEditingRows[index];
            return newEditingRows;
        });
    };

    return (
        <Grid container spacing={2} className={classes.grid}>
            <Grid container width="100%">
                <Table>
                    <TableHead>
                        <TableRow style={{backgroundColor: "#000"}}>
                            <TableCell style={{color:"#fff",fontWeight: "bold"}}>Description</TableCell>
                            <TableCell style={{color:"#fff",fontWeight: "bold"}}>Price</TableCell>
                            <TableCell style={{color:"#fff",fontWeight: "bold"}}>Cost</TableCell>
                            <TableCell style={{color:"#fff",fontWeight: "bold"}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arrayData.map((object, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        value={object.description}
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        size="small"
                                        name="description"
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>

                                <TableCell>
                                    <TextField
                                        value={parseFloat(object.price).toFixed(2)}
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        size="small"
                                        name="price"
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={parseFloat(object.coste).toFixed(2)}
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        size="small"
                                        name="coste"
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                            toggleEditing(index);
                                        }}
                                    >
                                        <span style={{display:"inline-flex", alignItems:"center",justifyContent:"center",width:"28px", height:"28px",padding:'4px', borderRadius:'4px',backgroundColor:"#019E59"}}>
                                            <Edit />
                                        </span>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

const View = (props) => {
    const {
        basicData,
        backData,
        frontData,
        indexTab,
        handleChangeTab,
        handleChangeTextField,
        onEdit,
        onDiscard,
    } = props;
    const classes = useStyles();

    const fieldOrder = [
        'Deal',
        'Date',
        'NeUs',
        // 'Associate Num',
        'Sales Person 1',
        'Sales Person 2',
        'FIM',
        'Unit',
        'Special Finance',
        'Book Comm',
    ];
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                {fieldOrder.map((field, index) => {
                    const keyObject = Object.keys(basicData).find(
                        (key) => basicData[key].title === field
                    );
                    if (!keyObject || keyObject === 'payment_data' || keyObject === '_id') {
                        return null;
                    }
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={basicData[keyObject].title}>
                            <TextField
                                label={basicData[keyObject].title}
                                value={basicData[keyObject]?.value || " "}
                                name={keyObject}
                                variant="outlined"
                                fullWidth
                                size="small"
                                onChange={(e) => handleChangeTextField(e, 2, null)}
                            />
                        </Grid>
                    );
                })}
                {Object.keys(basicData).map((keyObject, index) => {
                    if (keyObject === 'HB') {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <TextField
                                    label="HoldBack"
                                    value={basicData[keyObject]?.value || " "}
                                    name={keyObject}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handleChangeTextField(e, 2, null)}
                                />
                            </Grid>
                        );
                    }
                })}
                {Object.keys(basicData).map((keyObject, index) => {
                    if (keyObject === 'DAP') {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <TextField
                                    label="DAP"
                                    value={basicData[keyObject]?.value 
                                        || " "
                                    }
                                    name={keyObject}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handleChangeTextField(e, 2, null)}
                                />
                            </Grid>
                        );
                    }
                })}
                {Object.keys(basicData).map((keyObject, index) => {
                    if (keyObject === 'assoc_pre_comm') {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <TextField
                                    label={basicData[keyObject].title}
                                    value={basicData[keyObject]?.value || " "}
                                    name={keyObject}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => handleChangeTextField(e, 2, null)}
                                />
                            </Grid>
                        );
                    }
                })}
            </Grid>
            {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldOrder.map((field, index) => {
                                const keyObject = Object.keys(basicData).find(
                                    (key) => basicData[key].title === field
                                );

                                if (
                                    !keyObject ||
                                    keyObject === 'payment_data' ||
                                    keyObject === '_id'
                                ) {
                                    return null;
                                }

                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography>{basicData[keyObject].title}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={basicData[keyObject].value}
                                                name={keyObject}
                                                onChange={(e) => handleChangeTextField(e, 2, null)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'HB') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>HoldBack</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'DAP') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>DAP</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'assoc_pre_comm') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>
                                                    {basicData[keyObject].title}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </Grid>
                ;
            </Grid> */}

            <Paper className={classes.containerTabs}>
                <Tabs value={indexTab} onChange={handleChangeTab} aria-label="simple tabs example">
                    <Tab label="Front" className={classes.tabs} />
                    <Tab label="Back" className={classes.tabs} />
                </Tabs>
            </Paper>
            <TabPanel
                value={indexTab}
                index={0}
                arrayData={frontData}
                handleChangeTextField={handleChangeTextField}
            />
            <TabPanel
                value={indexTab}
                index={1}
                arrayData={backData}
                handleChangeTextField={handleChangeTextField}
            />
            {/* <Fab color="secondary" variant='extended' aria-label="edit" className={classes.fab} onClick={onEdit}>
                <Save/>
                {" "} Save
            </Fab> */}
            <div className={classes.btnContainer}>
                <Button
                    startIcon={<Delete />}
                    variant="contained"
                    size="medium"
                    color='error'
                    onClick={onDiscard}
                    className={classes.btnDanger}
                >
                    Discard
                </Button>
                <Button
                    startIcon={<Save />}
                    variant="contained"
                    size="medium"
                    color='success'
                    onClick={onEdit}
                    className={classes.btnSuccess}
                >
                    Save
                </Button>
            </div>
        </Paper>
    );
};

View.propTypes = {
    basicData: PropTypes.object.isRequired,
    backData: PropTypes.array.isRequired,
    frontData: PropTypes.array.isRequired,
    indexTab: PropTypes.number.isRequired,
    handleChangeTab: PropTypes.func.isRequired,
    handleChangeTextField: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
};

export default View;
