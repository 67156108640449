import { put, take, takeLatest, select, call, race, delay, fork } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { io } from 'socket.io-client';

import { snackBarAction } from '../actions/utilitiesAction';
import { URL_API } from '../../consts/urls';
import {
    TERM_COMPLETE_REQUEST,
    TERM_UPDATE_START,
    TERM_LIST_START,
    TERM_LIST_COMPLETE,
    TERM_LIST_ERROR,
} from '../../consts/actionTypes';
import { token } from '../selectors';

const connectIO = async (token) => {
    if (!token) return;
    return io(URL_API, { extraHeaders: { token } });
};

const fetchTermsIO = (socket) =>
    eventChannel((emit) => {
        socket.on('term', (data, response) => {
            emit(data);
        });
        return () => {
            emit(END);
        };
    });

export function* termList() {
    const accessToken = yield select(token);

    // const socket = io(URL_API, { extraHeaders: { token: accessToken } });
    const socket = yield call(connectIO, accessToken);

    const chan = yield call(fetchTermsIO, socket);

    while (true) {
        try {
            const results = yield take(chan);
            yield put({ type: TERM_LIST_COMPLETE, results });
        } catch (error) {
            const errorResponse = error.response;
            yield put({ type: TERM_LIST_ERROR, errorResponse });
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        }
        // yield put(isLoadingAction(false));
    }
}

const updateTerm = (socket, id, data, token) =>
    eventChannel((emit) => {
        socket.emit('term', id, data, token);
        return () => {
            emit(END);
        };
    });

export function* termUpdate({ id, data }) {
    const accessToken = yield select(token);

    const { timeout } = yield race({
        connected: call(connectIO, accessToken),
        timeout: delay(2000),
    });

    // const socket = io(URL_API, { auth: { token: accessToken } });

    const socket = yield call(connectIO, accessToken);
    // const results = yield call(apiCall, `${TERM}/${id}`, data, responseToken, 'PUT');
    const chan = yield call(updateTerm, socket, id, data);

    while (true) {
        try {
            const results = yield take(chan);
            yield put({ type: TERM_COMPLETE_REQUEST, results });
            yield put(
                snackBarAction({
                    open: true,
                    msg: results.data.message,
                    type: 'success',
                })
            );
        } catch (error) {
            const errorResponse = error.response;
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error create associate: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
            // yield put(isLoadingAction(false));
        }
    }
}

export default function* getAsyncDataWatcher() {
    // yield takeLatest('GET_ASYNCE_DATA', runOurAction);
    // yield takeLatest('SENT_SOCKET_MESSAGE', sendSocketMessage);
    // yield takeLatest(TERM_UPDATE_START, termUpdate);
    yield takeLatest(TERM_LIST_START, termList);
}
