import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    ADJUSTMENT_LIST_START,
    ADJUSTMENT_LIST_COMPLETE,
    ADJUSTMENT_LIST_ERROR,
    ADJUSTMENT_UPDATE_START,
    ADJUSTMENT_UPDATE_COMPLETE,
    ADJUSTMENT_UPDATE_ERROR,
    ADJUSTMENT_SINGLE_START,
    ADJUSTMENT_SINGLE_COMPLETE,
    ADJUSTMENT_SINGLE_ERROR,
    ADJUSTMENT_CREATE_START,
    ADJUSTMENT_CREATE_COMPLETE,
    ADJUSTMENT_CREATE_ERROR,
    ADJUSTMENT_DELETE_START,
    ADJUSTMENT_DELETE_COMPLETE,
    ADJUSTMENT_DELETE_ERROR,
    CREATE_UNIT_ADJUSTMENT_START,
    EDIT_UNIT_ADJUSTMENT_START,
    DELETE_UNIT_ADJUSTMENT_START,
} from '../../consts/actionTypes';
import { apiCall } from '../api';
import { ADJUSTMENT, UNIT_ADJUSTMENT } from '../../consts/urls';
import { washoutSearch } from '../actions/washoutAction';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import {
    token,
    dmsCurrentWashoutSelector,
    dateCurrentWashoutSelector,
    typeAssoCurrentWashoutSelector,
} from '../selectors';

function* searchCurrent() {
    const dmsCurrent = yield select(dmsCurrentWashoutSelector);
    const dateCurrent = yield select(dateCurrentWashoutSelector);
    const typeAssoCurrent = yield select(typeAssoCurrentWashoutSelector);
    yield put(washoutSearch(dmsCurrent, dateCurrent, typeAssoCurrent));
}

export function* listAdjustment() {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, ADJUSTMENT, null, responseToken, 'GET');
        yield put({ type: ADJUSTMENT_LIST_COMPLETE, results });
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ADJUSTMENT_LIST_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error Load List: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}
export function* updateAdjustment({ payload, obj }) {
    try {
        const responseToken = yield select(token);
        const id = payload._id;
        delete payload._id;

        const results = yield call(apiCall, `${ADJUSTMENT}/${id}`, payload, responseToken, 'PUT');
        yield put({ type: ADJUSTMENT_UPDATE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        if (obj === 'associateWashout') {
            yield* searchCurrent();
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ADJUSTMENT_UPDATE_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error update: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* deleteAdjustment({ idItem, obj }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${ADJUSTMENT}/${idItem}`,
            null,
            responseToken,
            'DELETE'
        );
        yield put({ type: ADJUSTMENT_DELETE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        if (obj === 'associateWashout') {
            yield* searchCurrent();
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ADJUSTMENT_DELETE_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error delete: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* singleAdjustment({ payload }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${ADJUSTMENT}/${payload}`, null, responseToken, 'GET');
        yield put({ type: ADJUSTMENT_SINGLE_COMPLETE, results });
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ADJUSTMENT_SINGLE_ERROR, errorResponse });
    }
}
export function* createAdjustment({ payload, obj }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, ADJUSTMENT, payload, responseToken, 'POST');
        yield put({ type: ADJUSTMENT_CREATE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        if (obj === 'associateWashout') {
            yield* searchCurrent();
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ADJUSTMENT_CREATE_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error delete: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* createUnitAdjustment({ objAddCar }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, UNIT_ADJUSTMENT, objAddCar, responseToken, 'POST');

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* searchCurrent();
    } catch (error) {
        //const errorResponse = error.response;
        console.log(error);
        /*
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error delete: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
        yield put(isLoadingAction(false));
    }
}
export function* editUnitAdjustment({ objAddCar, id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${UNIT_ADJUSTMENT}/${id}`,
            objAddCar,
            responseToken,
            'PUT'
        );

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* searchCurrent();
    } catch (error) {
        //const errorResponse = error.response;
        console.log(error);
        /*
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error delete: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
        yield put(isLoadingAction(false));
    }
}
export function* deleteUnitAdjustment({ id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${UNIT_ADJUSTMENT}/${id}`,
            null,
            responseToken,
            'DELETE'
        );

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* searchCurrent();
    } catch (error) {
        //const errorResponse = error.response;
        console.log(error);
        /*
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error delete: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));*/
        yield put(isLoadingAction(false));
    }
}

export default function* adjustment() {
    yield takeLatest(ADJUSTMENT_LIST_START, listAdjustment);
    yield takeLatest(ADJUSTMENT_SINGLE_START, singleAdjustment);
    yield takeLatest(ADJUSTMENT_CREATE_START, createAdjustment);
    yield takeLatest(ADJUSTMENT_UPDATE_START, updateAdjustment);
    yield takeLatest(ADJUSTMENT_DELETE_START, deleteAdjustment);
    yield takeLatest(CREATE_UNIT_ADJUSTMENT_START, createUnitAdjustment);
    yield takeLatest(EDIT_UNIT_ADJUSTMENT_START, editUnitAdjustment);
    yield takeLatest(DELETE_UNIT_ADJUSTMENT_START, deleteUnitAdjustment);
}
