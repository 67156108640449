import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { SelectDropDown } from '../../components';
import { months, years } from '../../consts/date';

export const ReportsActions = React.memo((props) => {
    const {
        dataSearch,
        onChangeInput,
        classes,
        typeValue,
        typeHandleChange,
        radioOptions,
        isDisableType,
    } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: 400,
                alignItems: 'center',
            }}
        >
            <SelectDropDown
                labelName={'Month'}
                nameSelect={'month'}
                valueProp={dataSearch.month}
                onChangeProp={onChangeInput}
                objectList={months}
                keyValue={'code'}
                keyLabel={'name'}
            />
            <SelectDropDown
                labelName={'Year'}
                nameSelect={'year'}
                valueProp={dataSearch.year}
                onChangeProp={onChangeInput}
                objectList={years}
                keyValue={'code'}
                keyLabel={'name'}
            />
            {!isDisableType && (
                <div className={classes.radioGroup}>
                    <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={typeValue}
                        onChange={typeHandleChange}
                    >
                        {React.Children.toArray(
                            radioOptions.map((value) => (
                                <FormControlLabel value={value} control={<Radio />} label={value} />
                            ))
                        )}
                    </RadioGroup>
                </div>
            )}
        </div>
    );
});
ReportsActions.propTypes = {
    isDisableType: PropTypes.bool,
};

ReportsActions.defaultProps = {
    isDisableType: false,
};
