import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    ASSOCIATE_LIST_START,
    ASSOCIATE_LIST_COMPLETE,
    ASSOCIATE_LIST_ERROR,
    ASSOCIATE_REGISTER_START,
    ASSOCIATE_REGISTER_COMPLETE,
    ASSOCIATE_DELETE_START,
    ASSOCIATE_DELETE_COMPLETE,
    ASSOCIATE_DELETE_ERROR,
    ASSOCIATE_LIST_BY_TYPE_START,
    ASSOCIATE_LIST_BY_TYPE_COMPLETE,
    ASSOCIATE_LIST_BY_TYPE_ERROR,
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction, idItemSeletedAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { ASSOCIATE_LIST, ASSOCIATE, ASSOCIATE_LIST_BY_TYPE } from '../../consts/urls';
import { token, idItemSelectedSelector, associateSelector } from '../selectors';

export function* list() {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, ASSOCIATE_LIST, null, responseToken, 'GET');
        yield put({ type: ASSOCIATE_LIST_COMPLETE, results });
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ASSOCIATE_LIST_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error Load List: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}
export function* listByType() {
    try {
        const responseToken = yield select(token);
        const resultsSeller = yield call(
            apiCall,
            ASSOCIATE_LIST_BY_TYPE + 'Seller',
            null,
            responseToken,
            'GET'
        );
        const resultsFim = yield call(
            apiCall,
            ASSOCIATE_LIST_BY_TYPE + 'FIM',
            null,
            responseToken,
            'GET'
        );
        const resultsTM = yield call(
            apiCall,
            ASSOCIATE_LIST_BY_TYPE + 'TM',
            null,
            responseToken,
            'GET'
        );

        yield put({ type: ASSOCIATE_LIST_BY_TYPE_COMPLETE, resultsSeller, resultsFim, resultsTM });
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ASSOCIATE_LIST_BY_TYPE_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error Load List: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}
export function* register() {
    try {
        const responseToken = yield select(token);
        const data = yield select(associateSelector);
        const dms = data.dms;
        delete data.dms;
        const results = yield call(apiCall, ASSOCIATE + dms, data, responseToken, 'POST');
        yield put({ type: ASSOCIATE_REGISTER_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* deleteR() {
    try {
        const responseToken = yield select(token);
        const idItem = yield select(idItemSelectedSelector);
        const results = yield call(apiCall, ASSOCIATE + idItem, null, responseToken, 'DELETE');
        yield put({ type: ASSOCIATE_DELETE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put({ type: ASSOCIATE_DELETE_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error delete associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(idItemSeletedAction(null));
        yield put(isLoadingAction(false));
    }
}

export default function* associate() {
    yield takeLatest(ASSOCIATE_LIST_START, list);
    yield takeLatest(ASSOCIATE_LIST_BY_TYPE_START, listByType);
    yield takeLatest(ASSOCIATE_REGISTER_START, register);
    yield takeLatest(ASSOCIATE_DELETE_START, deleteR);
}
