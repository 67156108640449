import {
    REPORT_TOTALS_COMPLETE,
    REPORT_SALES_COMPLETE,
    CLEAN_REPORTS,
    FETCH_REPORT_PAYOUT_COMPLETE,
    FETCH_REPORT_TMS_COMPLETE,
    FETCH_REPORT_TMS_SALES_COMPLETE,
} from '../../consts/actionTypes';
import { dollar } from '../../consts/dollarFormat';

const initialState = {
    listResponse: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REPORT_TOTALS_COMPLETE:
            const response = action.results.data.data;
            const responseTotal = action.results.data.totals;
            let listResponseTemp = [];
            let newTotals = {};
            for (const obj of response) {
                let newobj = {};
                for (const key in obj) {
                    if (key.slice(0, 5) === 'total') {
                        newobj = { ...newobj, [key]: dollar(obj[key]) };
                    } else newobj = { ...newobj, [key]: obj[key] };
                }
                listResponseTemp = [...listResponseTemp, newobj];
            }
            listResponseTemp = [
                ...listResponseTemp,
                {
                    assoc: 'TOTAL MONTH',
                    total_adjustments: dollar(responseTotal.report_total_adjustments),
                    total_adjustments_new: dollar(responseTotal.report_total_adjustments_new),
                    total_adjustments_no_new_used: dollar(
                        responseTotal.report_total_adjustments_no_new_used
                    ),
                    total_adjustments_used: dollar(responseTotal.report_total_adjustments_used),
                    total_assoc: dollar(responseTotal.report_total),
                    total_new_cars: dollar(responseTotal.report_total_new),
                    total_used_cars: dollar(responseTotal.report_total_used),
                    total_gp: dollar(responseTotal.report_total_gp),
                    total_increase: dollar(responseTotal.report_total_increase),
                    total_draws: dollar(responseTotal.report_total_draws),
                },
            ];

            return {
                ...state,
                listResponse: listResponseTemp,
            };
        case REPORT_SALES_COMPLETE:
            return {
                ...state,
                listResponse: action.results.data.data,
            };
        case FETCH_REPORT_PAYOUT_COMPLETE: {
            const responseTotal = action.results.data.totals;
            let listResponseTemp = [];

            listResponseTemp = [
                ...action.results.data.data,
                {
                    assoc: 'TOTAL MONTH',
                    total_new_cars_gp_front_end: responseTotal.report_total_new_cars_gp_front,
                    total_new_cars_payout: responseTotal.report_total_new_cars_payout,
                    total_new_cars_gp_back_end: responseTotal.report_total_new_cars_gp_back,
                    total_used_cars_gp_front_end: responseTotal.report_total_used_cars_gp_front,
                    total_used_cars_payout: responseTotal.report_total_used_cars_payout,
                    total_used_cars_gp_back_end: responseTotal.report_total_used_cars_gp_back,
                },
            ];
            return {
                ...state,
                listResponse: listResponseTemp,
            };
        }
        case FETCH_REPORT_TMS_COMPLETE: {
            let listResponseTemp = action.results.data.report;
            listResponseTemp.forEach((element) => {
                let obj = element.totals;
                let obj2 = {
                    associate_name: '',
                    total_back: '',
                    total_front: '',
                    total_new_units: '',
                    total_units: null,
                    total_used_units: null,
                };
                let obj3 = {
                    associate_name: 'TOTAL',
                    total_back: obj.team_total_back,
                    total_front: obj.team_total_front,
                    total_new_units: obj.team_total_new_units,
                    total_units: obj.team_total_units,
                    total_used_units: obj.team_total_used_units,
                };

                element.data.push(obj2);
                element.data.push(obj3);
            });

            return {
                ...state,
                listResponse: action.results.data,
            };
        }
        case FETCH_REPORT_TMS_SALES_COMPLETE: {
            let listResponseTemp = action.results.data.report;
            listResponseTemp.forEach((element) => {
                let obj = element.totals;
                let obj2 = {
                    associate_name: '',
                    total_back: '',
                    total_front: '',
                    total_new_units: '',
                    total_units: null,
                    total_used_units: null,
                };
                let obj3 = {
                    associate_name: 'TOTAL',
                    total_back: obj.team_total_back,
                    total_front: obj.team_total_front,
                    total_new_units: obj.team_total_new_units,
                    total_units: obj.team_total_units,
                    total_used_units: obj.team_total_used_units,
                };

                element.data.push(obj2);
                element.data.push(obj3);
            });

            return {
                ...state,
                listResponse: action.results.data,
            };
        }
        case CLEAN_REPORTS:
            return {
                ...state,
                listResponse: null,
            };
        default:
            return { ...state };
    }
}
