/**action de utilidades */
export const DIALOGMESSAGE = 'DIALOGMESSAGE';
export const IS_LOADING = 'IS_LOADING';
export const SNACKBAR = 'SNACKBAR';
export const ASSOCIATE_NAME_LIST = 'ASSOCIATE_NAME_LIST';
export const ID_ITEM_SELECTED = 'ID_ITEM_SELECTED';
/**-------------------- */

/**actiones del login */
export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_COMPLETE = 'SIGN_IN_COMPLETE';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
/**------------------ */

/**action del associate */
export const ASSOCIATE_CONF_CARD_ASSOCIATE = 'ASSOCIATE_CONF_CARD_ASSOCIATE';
export const ASSOCIATE_ASSOCIATE = 'ASSOCIATE_ASSOCIATE';

export const ASSOCIATE_LIST_START = 'ASSOCIATE_LIST_START';
export const ASSOCIATE_LIST_COMPLETE = 'ASSOCIATE_LIST_COMPLETE';
export const ASSOCIATE_LIST_ERROR = 'ASSOCIATE_LIST_ERROR';

export const ASSOCIATE_LIST_BY_TYPE_START = 'ASSOCIATE_LIST_BY_TYPE_START';
export const ASSOCIATE_LIST_BY_TYPE_COMPLETE = 'ASSOCIATE_LIST_BY_TYPE_COMPLETE';
export const ASSOCIATE_LIST_BY_TYPE_ERROR = 'ASSOCIATE_LIST_BY_TYPE_ERROR';

export const ASSOCIATE_REGISTER_START = 'ASSOCIATE_REGISTER_START';
export const ASSOCIATE_REGISTER_COMPLETE = 'ASSOCIATE_REGISTER_COMPLETE';
export const ASSOCIATE_REGISTER_ERROR = 'ASSOCIATE_REGISTER_ERROR';

export const ASSOCIATE_DELETE_START = 'ASSOCIATE_DELETE_START';
export const ASSOCIATE_DELETE_COMPLETE = 'ASSOCIATE_DELETE_COMPLETE';
export const ASSOCIATE_DELETE_ERROR = 'ASSOCIATE_DELETE_ERROR';

/**--------------- */
/**-actionWASHOUT_assoc_month */
export const WASHOUT_ASSOC_SEARCH_START = 'WASHOUT_ASSOC_SEARCH_START';
export const WASHOUT_ASSOC_SEARCH_COMPLETE = 'WASHOUT_ASSOC_SEARCH_COMPLETE';
export const WASHOUT_ASSOC_SEARCH_ERROR = 'WASHOUT_ASSOC_SEARCH_ERROR';

export const UPDATE_TABLE_SUBDEALS = 'UPDATE_TABLE_SUBDEALS';
export const WASHOUT_ASSOC_EDIT_START = 'WASHOUT_ASSOC_EDIT_START';
export const CLEAN_WASHOUT = 'CLEAN_WASHOUT';

export const ALL_WASHOUT_START = 'ALL_WASHOUT_START';
export const ALL_WASHOUT_COMPLETE = 'ALL_WASHOUT_COMPLETE';
export const ALL_WASHOUT_ERROR = 'ALL_WASHOUT_ERROR';

export const SUBDEAL_API_REVERSAL_SEARCH_START = 'SUBDEAL_API_REVERSAL_SEARCH_START';
export const SUBDEAL_API_REVERSAL_SEARCH_ERROR = 'SUBDEAL_API_REVERSAL_SEARCH_ERROR';
export const WASHOUT_REVERSAL_SEARCH_COMPLETE = 'WASHOUT_REVERSAL_SEARCH_COMPLETE';
/**---------------------------- */

/**---------------------------adjustment */
export const ADJUSTMENT_HEIGHT_CARD_CREATE = 'ADJUSTMENT_HEIGHT_CARD_CREATE';
export const ADJUSTMENT_LIST_START = 'ADJUSTMENT_LIST_START';
export const ADJUSTMENT_LIST_COMPLETE = 'ADJUSTMENT_LIST_COMPLETE';
export const ADJUSTMENT_LIST_ERROR = 'ADJUSTMENT_LIST_ERROR';

export const ADJUSTMENT_SINGLE_START = 'ADJUSTMENT_SINGLE_START';
export const ADJUSTMENT_SINGLE_COMPLETE = 'ADJUSTMENT_SINGLE_COMPLETE';
export const ADJUSTMENT_SINGLE_ERROR = 'ADJUSTMENT_SINGLE_ERROR';

export const ADJUSTMENT_CREATE_START = 'ADJUSTMENT_CREATE_START';
export const ADJUSTMENT_CREATE_COMPLETE = 'ADJUSTMENT_CREATE_COMPLETE';
export const ADJUSTMENT_CREATE_ERROR = 'ADJUSTMENT_CREATE_ERROR';

export const ADJUSTMENT_UPDATE_START = 'ADJUSTMENT_UPDATE_START';
export const ADJUSTMENT_UPDATE_COMPLETE = 'ADJUSTMENT_UPDATE_COMPLETE';
export const ADJUSTMENT_UPDATE_ERROR = 'ADJUSTMENT_UPDATE_ERROR';

export const ADJUSTMENT_DELETE_START = 'ADJUSTMENT_DELETE_START';
export const ADJUSTMENT_DELETE_COMPLETE = 'ADJUSTMENT_DELETE_COMPLETE';
export const ADJUSTMENT_DELETE_ERROR = 'ADJUSTMENT_DELETE_ERROR';

export const ADJUSTMENT_OPEN_DILOG = 'ADJUSTMENT_OPEN_DILOG';
export const ADJUSTMENT_SINGLE = 'ADJUSTMENT_SINGLE';
export const ADJUSTMENT_LIST_WASHOUT = 'ADJUSTMENT_LIST_WASHOUT';

export const CLEAN_ADJUSTMENT_WASHOUT = 'CLEAN_ADJUSTMENT_WASHOUT';

/**---------------------------adjustment  fin*/

/*** extraction modulo*/
export const EXTRACTION_START = 'EXTRACTION_START';
/******************** */

/**subdeal_api */
export const SUBDEAL_API_START_SEARCH = 'SUBDEAL_API_START_SEARCH';
export const SUBDEAL_API_RESPONSE_COMPLETE = 'SUBDEAL_API_RESPONSE_COMPLETE';
export const SUBDEAL_API_CHANGES_KEY_DATA = 'SUBDEAL_API_CHANGES_KEY_DATA';
export const SUBDEAL_API_EDIT_START = 'SUBDEAL_API_EDIT_START';
export const SUBDEAL_API_EDIT_COMPLETE = 'SUBDEAL_API_EDIT_COMPLETE';
export const SUBDEAL_API_DELETE_START = 'SUBDEAL_API_DELETE_START';
export const SET_SUBDEAL_ON_INTIAL_STATE = 'SET_SUBDEAL_ON_INTIAL_STATE';
export const SUBDEAL_EDIT_DISCARD = 'SUBDEAL_EDIT_DISCARD';
/******************** */

/**search modulo */
export const CLEAN_SEARCH_LIST_RESPONSE = 'CLEAN_SEARCH_LIST_RESPONSE';
export const SEARCH_DEAL_START = 'SEARCH_DEAL_START';
export const SEARCH_DEAL_COMPLETE = 'SEARCH_DEAL_COMPLETE';

export const SEARCH_DEALS_BY_MONTH_START = 'SEARCH_DEALS_BY_MONTH_START';
export const SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE = 'SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE';

export const SEARCH_SUBDEAL_BY_ASSOCIATE_START = 'SEARCH_SUBDEAL_BY_ASSOCIATE_START';
export const SEARCH_DEALS_BY_MONTH_COMPLETE = 'SEARCH_DEALS_BY_MONTH_COMPLETE';

export const SEARCH_TEXT_IN_DEAL = 'SEARCH_TEXT_IN_DEAL';
export const EDIT_DEAL_START = 'EDIT_DEAL_START';

/*** */

/**Pay Plan modulo */
export const PAYPLAN_LIST_START = 'PAYPLAN_LIST_START';
export const PAYPLAN_LIST_COMPLETE = 'PAYPLAN_LIST_COMPLETE';
export const PAYPLAN_LIST_ERROR = 'PAYPLAN_LIST_ERROR';

export const PAYPLAN_CREATE_START = 'PAYPLAN_CREATE_START';
export const PAYPLAN_CREATE_COMPLETE = 'PAYPLAN_CREATE_COMPLETE';
export const PAYPLAN_CREATE_ERROR = 'PAYPLAN_CREATE_ERROR';

export const PAYPLAN_UPDATE_START = 'PAYPLAN_UPDATE_START';
export const PAYPLAN_UPDATE_COMPLETE = 'PAYPLAN_UPDATE_COMPLETE';
export const PAYPLAN_UPDATE_ERROR = 'PAYPLAN_UPDATE_ERROR';

export const PAYPLAN_DELETE_START = 'PAYPLAN_DELETE_START';
export const PAYPLAN_DELETE_COMPLETE = 'PAYPLAN_DELETE_COMPLETE';
export const PAYPLAN_DELETE_ERROR = 'PAYPLAN_DELETE_ERROR';

export const PAYPLAN_SEARCH_START = 'PAYPLAN_SEARCH_START';
export const PAYPLAN_SEARCH_COMPLETE = 'PAYPLAN_SEARCH_COMPLETE';
export const PAYPLAN_SEARCH_ERROR = 'PAYPLAN_SEARCH_ERROR';

/***** */

/**Term modulo */
export const TERM_LIST_START = 'TERM_LIST_START';
export const TERM_LIST_COMPLETE = 'TERM_LIST_COMPLETE';
export const TERM_LIST_ERROR = 'TERM_LIST_ERROR';

export const TERM_CREATE_START = 'TERM_CREATE_START';
export const TERM_CREATE_COMPLETE = 'TERM_CREATE_COMPLETE';
export const TERM_CREATE_ERROR = 'TERM_CREATE_ERROR';

export const TERM_UPDATE_START = 'TERM_UPDATE_START';
export const TERM_UPDATE_COMPLETE = 'TERM_UPDATE_COMPLETE';
export const TERM_UPDATE_ERROR = 'TERM_UPDATE_ERROR';

export const TERM_DELETE_START = 'TERM_DELETE_START';
export const TERM_DELETE_COMPLETE = 'TERM_DELETE_COMPLETE';
export const TERM_DELETE_ERROR = 'TERM_DELETE_ERROR';

export const TERM_SEARCH_START = 'TERM_SEARCH_START';
export const TERM_SEARCH_COMPLETE = 'TERM_SEARCH_COMPLETE';
export const TERM_SEARCH_ERROR = 'TERM_SEARCH_ERROR';

export const TERM_PAYPLAN_SEARCH_START = 'TERM_PAYPLAN_SEARCH_START';
export const TERM_PAYPLAN_SEARCH_COMPLETE = 'TERM_PAYPLAN_SEARCH_COMPLETE';
export const TERM_PAYPLAN_SEARCH_ERROR = 'TERM_SEARCH_ERROR';

export const TERM_COMPLETE_REQUEST = 'TERM_COMPLETE_REQUEST';

/***** */
/***unit_adjustment */
export const CREATE_UNIT_ADJUSTMENT_START = 'CREATE_UNIT_ADJUSTMENT_START';
export const CREATE_UNIT_ADJUSTMENT_COMPLETE = 'CREATE_UNIT_ADJUSTMENT_COMPLETE';
export const EDIT_UNIT_ADJUSTMENT_START = 'EDIT_UNIT_ADJUSTMENT_START';
export const EDIT_UNIT_ADJUSTMENT_COMPLETE = 'EDIT_UNIT_ADJUSTMENT_COMPLETE';
export const DELETE_UNIT_ADJUSTMENT_START = 'DELETE_UNIT_ADJUSTMENT_START';
export const DELETE_UNIT_ADJUSTMENT_COMPLETE = 'DELETE_UNIT_ADJUSTMENT_COMPLETE';
/*** */
/*** Reports*/
export const REPORT_TOTALS_START = 'REPORT_TOTALS_START';
export const REPORT_TOTALS_COMPLETE = 'REPORT_TOTALS_COMPLETE';
export const REPORT_SALES_START = 'REPORT_SALES_START';
export const REPORT_SALES_COMPLETE = 'REPORT_SALES_COMPLETE';
export const CLEAN_REPORTS = 'CLEAN_REPORTS';
export const FETCH_REPORT_PAYOUT_START = 'FETCH_REPORT_PAYOUT_START';
export const FETCH_REPORT_PAYOUT_COMPLETE = 'FETCH_REPORT_PAYOUT_COMPLETE';
export const FETCH_REPORT_TMS_START = 'FETCH_REPORT_TMS_START';
export const FETCH_REPORT_TMS_COMPLETE = 'FETCH_REPORT_TMS_COMPLETE';
export const FETCH_REPORT_TMS_SALES_START = 'FETCH_REPORT_TMS_SALES_START';
export const FETCH_REPORT_TMS_SALES_COMPLETE = 'FETCH_REPORT_TMS_SALES_COMPLETE';

/*** */

/** TM **/
export const GET_TM_START = 'GET_TM_START';
export const GET_TM_COMPLETE = 'GET_TM_COMPLETE';
export const ADD_MEMBER_TO_TM_START = 'ADD_MEMBER_TO_TM_START';
export const DELETE_MEMBER_TO_TM_START = 'DELETE_MEMBER_TO_TM_START';
/** */


/** TRANSFER_DEAL **/
export const GET_FIM_START = 'GET_FIM_START';
export const GET_FIM_COMPLETE = 'GET_FIM_COMPLETE';
export const GET_SP_START = 'GET_SP_START';
export const GET_SP_COMPLETE = 'GET_SP_COMPLETE';
export const GET_DEAL_START = 'GET_DEAL_START';
export const GET_DEAL_COMPLETE = 'GET_DEAL_COMPLETE';
export const GET_TRANSFER_DEAL_START = 'GET_TRANSFER_DEAL';
export const GET_TRANSFER_DEAL_COMPLETE = 'GET_TRANSFER_DEAL_COMPLETE';
export const UPDATE_DEAL = 'UPDATE_DEAL';
export const DISCARD_DEAL_CHANGES = 'DISCARD_DEAL_CHANGES'; 
export const EDIT_TRANSFER_DEAL_START = 'EDIT_TRANSFER_DEAL_START';
export const EDIT_TRANSFER_DEAL_COMPLETE = 'EDIT_TRANSFER_DEAL_COMPLETE';
