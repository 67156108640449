import React, { forwardRef, useState } from 'react';
import { get } from 'lodash';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { List, ListItem, Button, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const SidebarNav = (props) => {
    const { pages, openNested, handleNestedClick, className, onClose, ...rest } = props;

    const classes = useStyles();

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            {pages.map((page) => {
                const isOpen = openNested === page.title;

                if (!page.children) {
                    return (
                        <ListItem className={classes.item} disableGutters key={page.title}>
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={CustomRouterLink}
                                to={page.href}
                                onClick={() => {
                                    onClose();
                                    handleNestedClick(page.title);
                                }}
                            >
                                <div className={classes.icon}>{page.icon}</div>
                                {page.title}
                            </Button>
                        </ListItem>
                    );
                }

                return (
                    <div key={page.title}>
                        <ListItem className={classes.item} disableGutters>
                            <Button
                                className={classes.button}
                                onClick={() => handleNestedClick(page.title)}
                            >
                                <div className={classes.icon}>{page.icon}</div>
                                {page.title}
                            </Button>
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {page.children.map((children) => (
                                    <ListItem
                                        className={classes.nested}
                                        disableGutters
                                        key={children.title}
                                    >
                                        <Button
                                            activeClassName={classes.active}
                                            className={classes.button}
                                            component={CustomRouterLink}
                                            to={children.href}
                                            onClick={onClose}
                                        >
                                            <div className={classes.icon}>{children.icon}</div>
                                            {children.title}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                );
            })}
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
};

export default SidebarNav;
