import axios from 'axios';
import { URL_API } from '../../consts/urls';
import {
    getLocalStorage,
    setLocalStorage,
    rmLocalStorage,
    REFRESH_TOKEN,
    ACCESS_TOKEN,
} from '../../consts/localStorage';

/** Refresh Token Implementation */

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => refreshTokenAndReAttempFunction(error)
);

const refreshTokenAndReAttempFunction = (error) => {
    const originalRequest = error.config;

    // if the refresh token is expired, go to login page
    if (error.response.status === 401 && originalRequest.url === `${URL_API}refresh_token`) {
        rmLocalStorage(ACCESS_TOKEN);
        rmLocalStorage(REFRESH_TOKEN);
        window.location.href = '/';

        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getLocalStorage(REFRESH_TOKEN);

        return axios
            .post(`${URL_API}refresh_token`, null, {
                headers: { Authorization: `JWT ${refreshToken}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    setLocalStorage(ACCESS_TOKEN, res.data.access_token);
                    originalRequest.headers['Authorization'] = 'JWT ' + res.data.access_token;
                    return axios(originalRequest);
                }
            });
    }
    return Promise.reject(error);
};

export const authCall = (url, data, headers, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers,
    });

export const apiCall = (url, data, token, method) =>
    axios({
        method,
        url: URL_API + url,
        data,
        headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
        },
    });
