import { get } from 'lodash';
import { TERM_LIST_COMPLETE, TERM_COMPLETE_REQUEST } from '../../consts/actionTypes';

const initialState = {
    listResponse: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TERM_LIST_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'results').reverse(),
            };
        case TERM_COMPLETE_REQUEST:
            return {
                ...state,
                listResponse: [],
            };

        default:
            return { ...state };
    }
}
