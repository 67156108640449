import React from 'react';
import { useStyles } from './styles';
import { CardTerm } from './components';
import PropTypes from 'prop-types';
import { DialogMessage, SimpleProgress } from '../../components';
import { Typography } from '@material-ui/core';
import { useState } from 'react';

const View = (props) => {
    const { listTerm, listPayPlan, associate, onEdit, isLoadingResponse, onAdd } = props;

    const [columns, setColumns] = useState([
        { title: 'Name', field: 'associate' },
        { title: 'payplan', field: 'payplan', initialEditValue: 'initial edit value' },
    ]);
    const listAssocietewithName = () => {
        let listAssociate = associate;
        let list = [];
        listTerm.ASSOCIATES.map((obj, i) => {
            let indexArray = listAssociate.findIndex((e) => {
                return e.code === obj.associate;
            });
            if (indexArray === -1) {
                return;
            }
            let newobj = { ...obj, name: listAssociate[indexArray].name };
            list.push(newobj);
        });
        return list;
    };

    const classes = useStyles();

    return (
        <div>
            <DialogMessage />
            <div className={classes.root}>
                <Typography variant="h3" gutterBottom>
                    New Term
                </Typography>
                <CardTerm
                    associate={associate}
                    listPayPlan={listPayPlan}
                    add={true}
                    onAdd={onAdd}
                />
            </div>
            <div className={classes.root}>
                <Typography variant="h3" gutterBottom>
                    List Term
                </Typography>
                {isLoadingResponse ? (
                    <SimpleProgress />
                ) : (
                    listTerm?.map((term, indexTerm) => {
                        return (
                            <div key={indexTerm}>
                                <CardTerm
                                    terms={term}
                                    associate={associate}
                                    listPayPlan={listPayPlan}
                                    onEdit={onEdit}
                                />
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

View.propTypes = {
    listTerm: PropTypes.array.isRequired,
    associate: PropTypes.array.isRequired,
    listPayPlan: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    isLoadingResponse: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default View;
