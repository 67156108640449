import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    searchDeal,
    searchSubDealByAssociate,
    searchDealByMonth,
    cleanListResponse,
} from '../../redux/actions/searchAction';
import { allWashoutAction, cleanWashout } from '../../redux/actions/washoutAction';
import {
    reportTotalsAction,
    reportSalesAction,
    cleanReportsAction,
    fetchReportPaypoutAction,
    fetchReportTMSAction,
    fetchReportTMSSalesAction,
} from '../../redux/actions/reportsActions';
import { isLoadingAction } from '../../redux/actions/utilitiesAction';
import {
    listResponseSelector,
    dinamicSelector,
    isLoading,
    listOrderDataSelector,
} from '../../redux/selectors';
import View from './view';
//searchReducer

export default (props) => {
    const { reducer } = props;
    const dispatch = useDispatch();
    const listResponse = useSelector((state) => listResponseSelector(state, reducer));
    const isLoadingResponse = useSelector((state) => isLoading(state));
    const listOrderDataState = useSelector((state) => listOrderDataSelector(state));
    const allWashoutState = useSelector((state) =>
        dinamicSelector(state, 'washoutReducer', 'listAllWashout')
    );

    const location = useLocation();
    const [cardConf, setCardConf] = useState({
        title: '',
        menu: '',
        actionDispatch: null,
    });
    const [dataSearch, setDataSearch] = useState({
        associate_num: '',
        deal: '',
        month: '',
        year: '',
        type: 'ALL',
    });
    useEffect(() => {
        return () => {
            dispatch(cleanListResponse());
            dispatch(cleanWashout());
            dispatch(cleanReportsAction());
        };
    }, []);

    console.log('[listResponse]', listResponse);

    useEffect(() => {
        setDataSearch({
            associate_num: '',
            deal: '',
            month: '',
            year: '',
            type: 'All',
        });
        let prop = {
            title: '',
            menu: '',
            actionDispatch: '',
        };
        switch (location.pathname) {
            case '/search_deals':
                prop = {
                    title: 'Search Deals By Number',
                    menu: 'a',
                    actionDispatch: searchDeal,
                };

                break;
            case '/search_subdeals':
                prop = {
                    title: 'Search Sub Deals By Number And Associate',
                    menu: 'b',
                    actionDispatch: searchSubDealByAssociate,
                };
                break;
            case '/search_deals_month':
                prop = {
                    title: 'Search Deals By Month',
                    menu: 'c',
                    actionDispatch: searchDealByMonth,
                };
                break;
            case '/all_washout':
                prop = {
                    title: 'All Washout By Month',
                    menu: 'd',
                    actionDispatch: allWashoutAction,
                };
                break;
            case '/reports_commission_month':
                prop = {
                    title: 'Report Commission Month',
                    menu: 'e',
                    actionDispatch: reportTotalsAction,
                };
                break;
            case '/reports_sales':
                prop = {
                    title: 'Report Sales Month',
                    menu: 'f',
                    actionDispatch: reportSalesAction,
                };
                break;
            case '/reports_payout':
                prop = {
                    title: 'Report Payout',
                    menu: 'g',
                    actionDispatch: fetchReportPaypoutAction,
                };
                break;
            case '/reports_tms':
                prop = {
                    title: 'Report Total TMs',
                    menu: 'h',
                    actionDispatch: fetchReportTMSAction,
                };
                break;
            case '/reports_tms_sales':
                prop = {
                    title: 'Report Total TMs Sales',
                    menu: 'i',
                    actionDispatch: fetchReportTMSSalesAction,
                };
                break;
            default:
                break;
        }
        setCardConf({ ...prop });
        dispatch(cleanListResponse());
        dispatch(cleanWashout());
        dispatch(cleanReportsAction());
    }, [location]);

    const onSearch = (type) => {
        dispatch(isLoadingAction(true));
        const actionDispatch = cardConf.actionDispatch;
        dispatch(actionDispatch({ ...dataSearch, type }));
    };
    const onChangeImput = (e) => {
        setDataSearch({
            ...dataSearch,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <View
            cardConf={cardConf}
            onSearch={onSearch}
            onChangeInput={onChangeImput}
            dataSearch={dataSearch}
            listResponse={listResponse}
            isLoadingResponse={isLoadingResponse}
            listOrderDataState={listOrderDataState}
            allWashoutState={allWashoutState}
        />
    );
};
