import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { Avatar, Typography, Button } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

const Profile = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();

    const user = {
        name: 'user',
        avatar: '/images/avatars/avatar_11.png',
        bio: 'user',
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={user.avatar}
                to="/settings"
            />
            <Typography className={classes.name} variant="h4">
                {user.name}
            </Typography>
            <Typography variant="body2">{user.bio}</Typography>
            <Button size="small" component={CustomRouterLink} to={'/'} startIcon={<LockOpenIcon />}>
                Authentication
            </Button>
        </div>
    );
};

Profile.propTypes = {
    className: PropTypes.string,
};

export default Profile;
