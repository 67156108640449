import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';
import { Typography, Link } from '@material-ui/core';

const Footer = (props) => {
    const { className, ...rest } = props;

    const classes = useStyles();
    /**felipe estuvo aqui*/
    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant="body1">
                &copy;{' '}
                <Link component="a" href="https://triallies.com/#" target="_blank">
                    Triallies LLC
                </Link>
                . 2023
            </Typography>
            <Typography variant="caption">Version Beta</Typography>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
