import React, { useEffect, useState } from 'react';
import View from './view';
import { useDispatch, useSelector } from 'react-redux';
import {
    associateListByType,
    associateDelete,
    associateConfCardAssociate,
    associateAssociate,
} from '../../../../redux/actions/associateAction';
import {
    dialogMessageAction,
    isLoadingAction,
    idItemSeletedAction,
} from '../../../../redux/actions/utilitiesAction';
import { listResponseSelector, token, isLoading } from '../../../../redux/selectors';

export default () => {
    const dispatch = useDispatch();
    const listResponse = useSelector((state) => listResponseSelector(state, 'associateReducer'));
    const responseToken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));
    useEffect(() => {
        if (!listResponse?.length && responseToken) {
            dispatch(isLoadingAction(true));
            dispatch(associateListByType());
        }
    }, [listResponse, responseToken, dispatch]);

    const onCLickDelete = () => {
        dispatch(isLoadingAction(true));
        dispatch(associateDelete());
        openConfirmDialog(null, false, null);
    };
    const openConfirmDialog = (id, openDialog, action) => {
        let confDialog = {};
        confDialog = {
            open: openDialog,
            title: 'comfirmation',
            text: 'Are you sure you want to delete this associate?',
            children: action,
        };
        dispatch(dialogMessageAction(confDialog));
        dispatch(idItemSeletedAction(id));
    };
    const onClickEditar = (itemSelect) => {
        dispatch(
            associateAssociate({
                name: itemSelect.name,
                type: itemSelect.type,
                dms: itemSelect.dms_number,
                enabled: itemSelect.enabled,
            })
        );
        dispatch(
            associateConfCardAssociate({
                cardTitle: 'Edit Associate',
                buttonCreate: 'Update',
                textFildDms: true,
            })
        );
    };
    const [indexTab, setIndexTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setIndexTab(newValue);
    };

    return (
        <View
            listResponse={listResponse}
            onCLickDelete={onCLickDelete}
            onClickEditar={onClickEditar}
            isLoadingResponse={isLoadingResponse}
            openConfirmDialog={openConfirmDialog}
            indexTab={indexTab}
            handleChangeTab={handleChangeTab}
        />
    );
};
