import React, { useState } from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { changeTextDealAction, editDealAction } from '../../../../redux/actions/searchAction';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';
import { listResponseSelector } from '../../../../redux/selectors';
import PropTypes from 'prop-types';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Typography,
    IconButton,
    TextField,
    InputBase,
} from '@material-ui/core';
import { SimpleProgress } from '../../components';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

const Index = (props) => {
    const { deal } = props;

    const dispatch = useDispatch();
    const data = useSelector((state) => listResponseSelector(state, 'searchReducer'));
    const [editable, setEditable] = useState(false);
    const [grossEditable, setGrossEditable] = useState(false);
    const [grossData, setGrossData] = useState({});
    const handleEditable = (bol) => {
        setEditable(bol);
    };

    const getGrossDataKey = (grossData, columnHeader) => {
        if (columnHeader == 'Cost' || columnHeader === 'Gross' || columnHeader === 'Sale')
            return grossData?.Title + grossData?.Description + columnHeader;

        return '';
    };

    const handleGrossData = (key, value) => {
        if (key) setGrossData({ ...grossData, [key]: value });
    };

    const handleGrossEdit = (bol) => {
        setGrossEditable(bol);
    };
    const createNameHeader = () => {
        let array = [];
        for (const key in data.grossProfitAnalysis.front[0]) {
            if (key !== 'keys') {
                array = [...array, key];
            }
        }
        return array;
    };
    const createNameHeaderCommissions = () => {
        let array = [];
        for (const key in data.commissions[0]) {
            if (key !== 'keys') {
                array = [...array, key];
            }
        }
        return array;
    };
    const onChangeText = (e) => {
        dispatch(changeTextDealAction(e.target.name, e.target.value));
    };
    const onEdit = () => {
        setEditable(false);
        dispatch(isLoadingAction(true));
        dispatch(editDealAction(deal));
    };
    const classes = useStyles();
    return (
        <div className={classes.divPrint}>
            <div className={classes.titleButton}>
                <Typography variant="h4" gutterBottom>
                    Customer Info
                </Typography>
                <IconButton
                    size="small"
                    onClick={() => (editable ? onEdit() : handleEditable(true))}
                >
                    {editable ? <DoneIcon fontSize="inherit" /> : <EditIcon fontSize="inherit" />}
                </IconButton>
                {editable && (
                    <IconButton size="small" onClick={() => handleEditable(false)}>
                        <CancelIcon fontSize="inherit" />
                    </IconButton>
                )}
            </div>
            <Grid container spacing={0}>
                {data.customerData.map((obj, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            className={classes.field}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                        >
                            {editable ? (
                                <div>
                                    <TextField
                                        label={obj.description}
                                        name={obj.code}
                                        size="small"
                                        value={obj.value}
                                        onChange={onChangeText}
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Typography variant="h6" gutterBottom>
                                        {obj.description}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {obj.value}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            <Typography variant="h4" gutterBottom>
                Deal Basic Data
            </Typography>
            <Grid container spacing={0}>
                {data.basicDataDeal.map((obj, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            className={classes.field}
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                        >
                            {editable ? (
                                <div>
                                    <TextField
                                        label={obj.description}
                                        name={obj.code}
                                        size="small"
                                        value={obj.value}
                                        onChange={onChangeText}
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Typography variant="h6" gutterBottom>
                                        {obj.description}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {obj.value}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            <div className={classes.titleButton}>
                <Typography variant="h4" gutterBottom>
                    Gross Profit Analysis
                </Typography>

                <IconButton
                    size="small"
                    onClick={() => (grossEditable ? onEdit() : handleGrossEdit(true))}
                >
                    {grossEditable ? (
                        <DoneIcon fontSize="inherit" />
                    ) : (
                        <EditIcon fontSize="inherit" />
                    )}
                </IconButton>
                {grossEditable && (
                    <IconButton size="small" onClick={() => handleGrossEdit(false)}>
                        <CancelIcon fontSize="inherit" />
                    </IconButton>
                )}
            </div>

            <TableContainer>
                <Table stickyHeader size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {createNameHeader().map((value, index) => (
                                <TableCell
                                    size="medium"
                                    padding="none"
                                    align="right"
                                    key={index}
                                    className={classes.cell}
                                >
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.grossProfitAnalysis.front.map((rowdata, indexRow) => {
                            return (
                                <TableRow key={indexRow}>
                                    {createNameHeader().map((column, index) => {
                                        let value = rowdata[column];
                                        if (typeof value === 'number' && value !== 0) {
                                            value = value.toFixed(2);
                                        }
                                        if (typeof value === 'string' && indexRow !== 0) {
                                            if (
                                                data.grossProfitAnalysis.front[indexRow - 1][
                                                    column
                                                ] === value
                                            ) {
                                                value = '';
                                            }
                                        }
                                        return (
                                            <TableCell
                                                size="small"
                                                align="right"
                                                padding="none"
                                                key={index}
                                                className={classes.cell}
                                            >
                                                {grossEditable &&
                                                column !== 'Title' &&
                                                column !== 'Description' ? (
                                                    <input
                                                        onChange={(e) =>
                                                            handleGrossData(
                                                                getGrossDataKey(rowdata, column),
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            getGrossDataKey(rowdata, column) in
                                                            grossData
                                                                ? grossData[
                                                                      getGrossDataKey(
                                                                          rowdata,
                                                                          column
                                                                      )
                                                                  ]
                                                                : value
                                                        }
                                                        style={{ textAlign: 'right' }}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            {createNameHeader().map((column, index) => {
                                let value = data.grossProfitAnalysis.totalfront[column];
                                if (typeof value === 'number' && value !== 0) {
                                    value = value.toFixed(2);
                                }

                                return (
                                    <TableCell
                                        size="small"
                                        align="right"
                                        padding="none"
                                        key={index}
                                        className={classes.cell}
                                    >
                                        {grossEditable &&
                                        column !== 'Title' &&
                                        column !== 'Description' ? (
                                            <input
                                                onChange={(e) =>
                                                    handleGrossData(
                                                        getGrossDataKey(
                                                            data.grossProfitAnalysis.totalfront,
                                                            column
                                                        ),
                                                        e.target.value
                                                    )
                                                }
                                                value={
                                                    getGrossDataKey(
                                                        data.grossProfitAnalysis.totalfront,
                                                        column
                                                    ) in grossData
                                                        ? grossData[
                                                              getGrossDataKey(
                                                                  data.grossProfitAnalysis
                                                                      .totalfront,
                                                                  column
                                                              )
                                                          ]
                                                        : value
                                                }
                                                style={{ textAlign: 'right' }}
                                            />
                                        ) : (
                                            value
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {data.grossProfitAnalysis.back.map((rowdata, indexRow) => {
                            return (
                                <TableRow key={indexRow}>
                                    {createNameHeader().map((column, index) => {
                                        let value = rowdata[column];
                                        if (typeof value === 'number' && value !== 0) {
                                            value = value.toFixed(2);
                                        }

                                        if (typeof value === 'string' && indexRow !== 0) {
                                            if (
                                                data.grossProfitAnalysis.back[indexRow - 1][
                                                    column
                                                ] === value
                                            ) {
                                                value = '';
                                            }
                                        }
                                        return (
                                            <TableCell
                                                size="small"
                                                align="right"
                                                padding="none"
                                                key={index}
                                                className={classes.cell}
                                            >
                                                {grossEditable &&
                                                column !== 'Title' &&
                                                column !== 'Description' ? (
                                                    <input
                                                        onChange={(e) =>
                                                            handleGrossData(
                                                                getGrossDataKey(rowdata, column),
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            getGrossDataKey(rowdata, column) in
                                                            grossData
                                                                ? grossData[
                                                                      getGrossDataKey(
                                                                          rowdata,
                                                                          column
                                                                      )
                                                                  ]
                                                                : value
                                                        }
                                                        style={{ textAlign: 'right' }}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            {createNameHeader().map((column, index) => {
                                let value = data.grossProfitAnalysis.totalback[column];
                                if (typeof value === 'number' && value !== 0) {
                                    value = value.toFixed(2);
                                }

                                return (
                                    <TableCell
                                        size="small"
                                        align="right"
                                        padding="none"
                                        key={index}
                                        className={classes.cell}
                                    >
                                        {grossEditable &&
                                        column !== 'Title' &&
                                        column !== 'Description' ? (
                                            <input
                                                onChange={(e) =>
                                                    handleGrossData(
                                                        getGrossDataKey(
                                                            data.grossProfitAnalysis.totalback,
                                                            column
                                                        ),
                                                        e.target.value
                                                    )
                                                }
                                                value={
                                                    getGrossDataKey(
                                                        data.grossProfitAnalysis.totalback,
                                                        column
                                                    ) in grossData
                                                        ? grossData[
                                                              getGrossDataKey(
                                                                  data.grossProfitAnalysis
                                                                      .totalback,
                                                                  column
                                                              )
                                                          ]
                                                        : value
                                                }
                                                style={{ textAlign: 'right' }}
                                            />
                                        ) : (
                                            value
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {data.grossProfitAnalysis.hasOwnProperty('surplush') ? (
                            <TableRow>
                                {createNameHeader().map((column, index) => {
                                    let value = data.grossProfitAnalysis.surplush[column];
                                    if (typeof value === 'number' && value !== 0) {
                                        value = value.toFixed(2);
                                    }

                                    return (
                                        <TableCell
                                            size="small"
                                            align="right"
                                            padding="none"
                                            key={index}
                                            className={classes.cell}
                                        >
                                            {grossEditable &&
                                            column !== 'Title' &&
                                            column !== '""' ? (
                                                <input
                                                    onChange={(e) =>
                                                        handleGrossData(
                                                            getGrossDataKey(index, column),
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        getGrossDataKey(index, column) in grossData
                                                            ? grossData[
                                                                  getGrossDataKey(index, column)
                                                              ]
                                                            : value
                                                    }
                                                    style={{ textAlign: 'right' }}
                                                />
                                            ) : (
                                                value
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ) : null}
                        <TableRow>
                            {createNameHeader().map((column, index) => {
                                let value = data.grossProfitAnalysis.totalsale[column];
                                if (typeof value === 'number' && value !== 0) {
                                    value = value.toFixed(2);
                                }

                                return (
                                    <TableCell
                                        size="small"
                                        align="right"
                                        padding="none"
                                        key={index}
                                        className={classes.cell}
                                    >
                                        {grossEditable &&
                                        column !== 'Title' &&
                                        column !== 'Description' ? (
                                            <input
                                                onChange={(e) =>
                                                    handleGrossData(
                                                        getGrossDataKey(
                                                            data.grossProfitAnalysis.totalsale,
                                                            column
                                                        ),
                                                        e.target.value
                                                    )
                                                }
                                                value={
                                                    getGrossDataKey(
                                                        data.grossProfitAnalysis.totalsale,
                                                        column
                                                    ) in grossData
                                                        ? grossData[
                                                              getGrossDataKey(
                                                                  data.grossProfitAnalysis
                                                                      .totalsale,
                                                                  column
                                                              )
                                                          ]
                                                        : value
                                                }
                                                style={{ textAlign: 'right' }}
                                            />
                                        ) : (
                                            value
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {data.grossProfitAnalysis.other.map((rowdata, indexRow) => {
                            return (
                                <TableRow key={indexRow}>
                                    {createNameHeader().map((column, index) => {
                                        let value = rowdata[column];
                                        if (typeof value === 'number') {
                                            if (value !== 0) {
                                                value = value.toFixed(2);
                                            } else {
                                                value = '';
                                            }
                                        }

                                        if (typeof value === 'string' && indexRow !== 0) {
                                            if (
                                                data.grossProfitAnalysis.other[indexRow - 1][
                                                    column
                                                ] === value
                                            ) {
                                                value = '';
                                            }
                                        }
                                        return (
                                            <TableCell
                                                size="small"
                                                align="right"
                                                padding="none"
                                                key={index}
                                                className={classes.cell}
                                            >
                                                {grossEditable &&
                                                column !== 'Title' &&
                                                column !== 'Description' ? (
                                                    <input
                                                        onChange={(e) =>
                                                            handleGrossData(
                                                                getGrossDataKey(rowdata, column),
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            getGrossDataKey(rowdata, column) in
                                                            grossData
                                                                ? grossData[
                                                                      getGrossDataKey(
                                                                          rowdata,
                                                                          column
                                                                      )
                                                                  ]
                                                                : value
                                                        }
                                                        style={{ textAlign: 'right' }}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {data.grossProfitAnalysis.hasOwnProperty('taxes')
                            ? data.grossProfitAnalysis.taxes.map((rowdata, indexRow) => {
                                  return (
                                      <TableRow key={indexRow}>
                                          {createNameHeader().map((column, index) => {
                                              let value = rowdata[column];
                                              if (typeof value === 'number') {
                                                  if (value !== 0) {
                                                      value = value.toFixed(2);
                                                  } else {
                                                      value = '';
                                                  }
                                              }

                                              if (typeof value === 'string' && indexRow !== 0) {
                                                  if (
                                                      data.grossProfitAnalysis.taxes[indexRow - 1][
                                                          column
                                                      ] === value
                                                  ) {
                                                      value = '';
                                                  }
                                              }
                                              return (
                                                  <TableCell
                                                      size="small"
                                                      align="right"
                                                      padding="none"
                                                      key={index}
                                                      className={classes.cell}
                                                  >
                                                      {grossEditable &&
                                                      column !== 'Title' &&
                                                      column !== 'Description' ? (
                                                          <input
                                                              onChange={(e) =>
                                                                  handleGrossData(
                                                                      getGrossDataKey(
                                                                          rowdata,
                                                                          column
                                                                      ),
                                                                      e.target.value
                                                                  )
                                                              }
                                                              value={
                                                                  getGrossDataKey(
                                                                      rowdata,
                                                                      column
                                                                  ) in grossData
                                                                      ? grossData[
                                                                            getGrossDataKey(
                                                                                rowdata,
                                                                                column
                                                                            )
                                                                        ]
                                                                      : value
                                                              }
                                                              style={{ textAlign: 'right' }}
                                                          />
                                                      ) : (
                                                          value
                                                      )}
                                                  </TableCell>
                                              );
                                          })}
                                      </TableRow>
                                  );
                              })
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h4" gutterBottom>
                Commissions
            </Typography>
            <TableContainer>
                <Table stickyHeader size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {createNameHeaderCommissions().map((value, index) => (
                                <TableCell
                                    size="medium"
                                    padding="none"
                                    align="right"
                                    key={index}
                                    className={classes.cell}
                                >
                                    {value}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.commissions.map((rowdata, indexRow) => {
                            return (
                                <TableRow key={indexRow}>
                                    {createNameHeaderCommissions().map((column, index) => {
                                        let value = rowdata[column];

                                        if (typeof value === 'number') {
                                            if (value !== 0) {
                                                value = value.toFixed(2);
                                            } else {
                                                value = '';
                                            }
                                        }
                                        if (column == 'role' || column == 'comm' || indexRow == 3) {
                                            return (
                                                <TableCell
                                                    size="small"
                                                    align="right"
                                                    padding="none"
                                                    key={index}
                                                    className={classes.cell}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        }
                                        if (
                                            indexRow == 2 &&
                                            (column == 'base' || column == 'total')
                                        ) {
                                            return (
                                                <TableCell
                                                    size="small"
                                                    align="right"
                                                    padding="none"
                                                    key={index}
                                                    className={classes.cell}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        }

                                        return (
                                            <TableCell
                                                size="small"
                                                align="right"
                                                padding="none"
                                                key={index}
                                                className={classes.cell}
                                            >
                                                {editable ? (
                                                    <InputBase
                                                        margin="dense"
                                                        value={value}
                                                        name={rowdata.keys[column]}
                                                        onChange={onChangeText}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

Index.propTypes = {
    deal: PropTypes.object.isRequired,
};
Index.defaultProps = {};

export default Index;
