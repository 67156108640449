import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import associateReducer from './associateReducer';
import washoutReducer from './washoutReducer';
import ListDealAssByMonth from './ListDealAssByMonth';
import adjustmentReducer from './adjustmentReducer';
import utilitiesReducer from './utilitiesReducer';
import subdealApiReducer from './subdealApiReducer';
import searchReducer from './searchReducer';
import payPlanReducer from './payPlanReducer';
import payPlanReducerV2 from './payPlanReducerV2';
import termReducer from './termReducer';
import reportReducer from './reportReducer';
import TMReducer from './TMReducer';
import transferReducer from './tranferReducer'

const rootReducer = combineReducers({
    loginReducer,
    associateReducer,
    washoutReducer,
    ListDealAssByMonth,
    adjustmentReducer,
    utilitiesReducer,
    subdealApiReducer,
    searchReducer,
    payPlanReducer,
    payPlanReducerV2,
    termReducer,
    reportReducer,
    TMReducer,
    transferReducer
});

export default rootReducer;
