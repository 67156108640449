import React from 'react';
import {
    PeopleAlt,
    Description,
    Payment,
    AccountBalanceWallet,
    Work,
    FindInPage,
    NoteAdd,
    WorkOff,
    Assignment,
    MonetizationOn,
    Timelapse,
} from '@material-ui/icons';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const pages = [
    {
        title: 'Deals',
        icon: <Work />,
        children: [
            {
                title: 'Search Deals By Number',
                href: '/search_deals',
                icon: <FindInPage />,
            },
            {
                title: 'Search By Deal',
                href: '/search_by_deals',
                icon: <FindInPage />,
            },
            {
                title: 'Search Sub Deals By Number And Associate',
                href: '/search_subdeals',
                icon: <FindInPage />,
            },
            {
                title: 'Search Deals By Month',
                href: '/search_deals_month',
                icon: <FindInPage />,
            } /*
            //   {
            //     title: 'Create subdeals by month',
            //     href: '/create_subdeals',
            //     icon: <NoteAdd />
            //   },*/,
            {
                title: 'List Sub Deals By Associate And Month',
                href: '/list_deals_ass_month',
                icon: <Assignment />,
            },
            {
                title: 'Revert Deal',
                href: '/revert_deal',
                icon: <WorkOff />,
            },
        ],
    },
    // {
    //     title: 'Adjustment',
    //     href: '/adjustment',
    //     icon: <AccountBalanceWallet />,
    // },
    {
        title: 'Adjustment',
        href: '/adjustment',
        icon: <AccountBalanceWallet />,
    },
    {
        title: 'Transfer Deals',
        href: '/transfer_deal',
        icon: <Payment />,
    },
    {
        title: 'Commission Pages',
        href: '/commission_pages',
        icon: <MonetizationOn />,
    },
    // {
    //     title: 'Payment Periods',
    //     href: '/payment_periods',
    //     icon: <Timelapse />,
    // },
    // {
    //     title: 'Payplans',
    //     href: '/payplans',
    //     icon: <Payment />,
    // },
    {
        title: 'Payplans',
        href: '/payplans',
        icon: <Payment />,
    },
    // {
    //     title: 'Term',
    //     href: '/term',
    //     icon: <ListAltIcon />,
    // },
    {
        title: 'Term',
        href: '/term',
        icon: <ListAltIcon />,
    },
    {
        title: 'Term Team Manager',
        href: '/team_manager',
        icon: <ListAltIcon />,
    },
    {
        title: 'Associates',
        href: '/associates',
        icon: <PeopleAlt />,
    },
    {
        title: 'Reports',
        icon: <Description />,
        children: [
            {
                title: 'Commision Month',
                href: '/reports_commission_month',
                icon: <Description />,
            },
            {
                title: 'Sales ',
                href: '/reports_sales',
                icon: <Description />,
            },
            {
                title: 'Payout',
                href: '/reports_payout',
                icon: <Description />,
            },
            {
                title: 'Total TMs',
                href: '/reports_tms',
                icon: <Description />,
            },
            {
                title: 'Total TMs Sales',
                href: '/reports_tms_sales',
                icon: <Description />,
            },
        ],
    },
];
