import { all } from 'redux-saga/effects';
import authUser from './loginSaga';
import associate from './associateSaga';
import subDeals from './washoutSaga';
import adjustment from './adjustmentSaga';
import extraction from './extractionSaga';
import subdealApi from './subdealApiSaga';
import search from './searchSaga';
import payPlan from './payPlanSaga';
import term from './termSaga';
import reports from './reportSaga';
import loadDataOnPageLoad from './localSaga';
import getAsyncDataWatcher from './socketSaga';
import TMwatch from './TMsaga';
import transferDealWatch from "./transferDealSaga"

export default function* rootSaga() {
    yield all([
        authUser(),
        associate(),
        subDeals(),
        adjustment(),
        extraction(),
        subdealApi(),
        search(),
        payPlan(),
        term(),
        reports(),
        loadDataOnPageLoad(),
        getAsyncDataWatcher(),
        TMwatch(),
        transferDealWatch()
    ]);
}
