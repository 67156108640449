import {
    ASSOCIATE_LIST_START,
    ASSOCIATE_REGISTER_START,
    ASSOCIATE_DELETE_START,
    ASSOCIATE_CONF_CARD_ASSOCIATE,
    ASSOCIATE_ASSOCIATE,
    ASSOCIATE_LIST_BY_TYPE_START,
} from '../../consts/actionTypes';

export const associateList = () => ({
    type: ASSOCIATE_LIST_START,
});
export const associateRegister = () => ({
    type: ASSOCIATE_REGISTER_START,
});

export const associateDelete = () => ({
    type: ASSOCIATE_DELETE_START,
});
export const associateConfCardAssociate = (payload) => ({
    type: ASSOCIATE_CONF_CARD_ASSOCIATE,
    payload,
});
export const associateAssociate = (payload) => ({
    type: ASSOCIATE_ASSOCIATE,
    payload,
});
export const associateListByType = () => ({
    type: ASSOCIATE_LIST_BY_TYPE_START,
});
