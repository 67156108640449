import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
    Paper,
    Card,
    Grid,
    CardContent,
    CardActions,
    TextField,
    IconButton,
    Collapse,
    Button,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { DeleteOutline, Edit, Check, Clear, Add } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { textFieldConf } from './textFieldConf';
import { CardAssociateTerm } from '../index';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const View = (props) => {
    const {
        handleClick,
        listTerm,
        onChangeData,
        listPayPlan,
        handlePositiveClick,
        handleNegativeClick,
        arrayAllAssociate,
        newObjInArrayAssociate,
        onChangeDataArray,
        onDeleteAssociateCard,
        expanded,
        handleExpandClick,
        add,
        openConfirmDialog,
        onDelete,
    } = props;

    const [columns, setColumns] = useState([
        { title: 'Name', field: 'name' },
        { title: 'payplan', field: 'payplan', initialEditValue: 'initial edit value' },
    ]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const accionDialog = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        openConfirmDialog(null, false);
                    }}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onDelete();
                    }}
                    color="primary"
                >
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <Paper className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        {textFieldConf.map((obj, i) => {
                            return (
                                <Grid
                                    item
                                    key={i}
                                    xl={obj.xl}
                                    lg={obj.lg}
                                    md={obj.md}
                                    sm={obj.sm}
                                    xs={obj.xs}
                                >
                                    <TextField
                                        disabled={!handleClick.edit}
                                        label={obj.value}
                                        name={obj.name}
                                        value={listTerm[obj.name]}
                                        onChange={onChangeData}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            );
                        })}

                        {add && (
                            <>
                                <IconButton onClick={handlePositiveClick}>
                                    <AddCircleRoundedIcon fontSize="large" color="secondary" />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        handleNegativeClick(accionDialog());
                                    }}
                                >
                                    <Clear fontSize="small" />
                                </IconButton>
                            </>
                        )}
                    </Grid>
                </CardContent>
                {!add && (
                    <>
                        <CardActions disableSpacing>
                            <IconButton onClick={handlePositiveClick}>
                                {handleClick.edit ? (
                                    <Check fontSize="small" />
                                ) : (
                                    <Edit fontSize="small" />
                                )}
                            </IconButton>

                            <IconButton
                                onClick={() => {
                                    handleNegativeClick(accionDialog());
                                }}
                            >
                                {handleClick.edit ? (
                                    <Clear fontSize="small" />
                                ) : (
                                    <DeleteOutline fontSize="small" />
                                )}
                            </IconButton>

                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Grid style={{ overflow: 'auto' }} container spacing={2}>
                                    <CardAssociateTerm
                                        isDisable={!handleClick.edit}
                                        handleChange={onChangeDataArray}
                                        listPayPlan={listPayPlan}
                                        arrayAllAssociate={arrayAllAssociate}
                                        onDeleteAssociateCard={onDeleteAssociateCard}
                                        onClean={handleClick.cleanCardTerm}
                                        listTerm={listTerm}
                                        handleClick={handleClick}
                                    >
                                        <CardAssociateTerm
                                            isDisable={!handleClick.edit}
                                            listPayPlan={listPayPlan}
                                            arrayAllAssociate={arrayAllAssociate}
                                            add={true}
                                            newObjInArrayAssociate={newObjInArrayAssociate}
                                            onClean={handleClick.cleanCardTerm}
                                        />
                                    </CardAssociateTerm>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </>
                )}
            </Card>
        </Paper>
    );
};

View.propTypes = {
    handleClick: PropTypes.object.isRequired,
    listTerm: PropTypes.object.isRequired,
    onChangeData: PropTypes.func.isRequired,
    listAutoComplete: PropTypes.array.isRequired,
    handleOnChangeAutoComplete: PropTypes.func.isRequired,
    autoPayPlan: PropTypes.object.isRequired,
    getEvent: PropTypes.func.isRequired,
    listPayPlan: PropTypes.array.isRequired,
    handlePositiveClick: PropTypes.func.isRequired,
    handleNegativeClick: PropTypes.func.isRequired,
    arrayAllAssociate: PropTypes.func.isRequired,
    newObjInArrayAssociate: PropTypes.func.isRequired,
    onChangeDataArray: PropTypes.func.isRequired,
    onDeleteAssociateCard: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleExpandClick: PropTypes.func.isRequired,
    add: PropTypes.bool.isRequired,
    openConfirmDialog: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
View.defaultProps = {};

export default View;
