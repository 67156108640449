import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import {
  GET_DEAL_COMPLETE,
  GET_DEAL_START,
  GET_FIM_COMPLETE,
  GET_FIM_START,
  GET_SP_COMPLETE,
  GET_SP_START,
  EDIT_TRANSFER_DEAL_START,
  GET_TRANSFER_DEAL_START,
  GET_TRANSFER_DEAL_COMPLETE,
  
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { token } from '../selectors';
import { TRANSFER_DEAL,SP,FIM,UPDATE_TRANSFER_DEAL } from '../../consts/urls';

const getTransferDealsData = async (url,token) => {
    const response = await apiCall(
        url,
        null,
        token,
        'GET'
    );
    return response.data;
}

function* getTransferDeals({ payload }) {
  try {
      const [sp,fim,deal] = yield all([
        call(getTransferDealsData, SP+'Seller', yield select(token)),
        call(getTransferDealsData, FIM+'FIM', yield select(token)),
        call(getTransferDealsData, TRANSFER_DEAL + payload, yield select(token)),
    ]);
      yield put({ type: GET_DEAL_COMPLETE, data: {
        sp,
        fim,
        deal
      } });
  } catch (error) {
      const errorResponse = error.response;

      yield put(
          snackBarAction({
              open: true,
              msg: 'error Load List: ' + errorResponse.data.message,
              type: 'error',
          })
      );
  }

  yield put(isLoadingAction(false));
}

function* getDeals (date) {
  try {
    yield put(isLoadingAction(true));
    const response = yield call(apiCall, TRANSFER_DEAL + date, null, yield select(token), 'GET');
    yield put({ type: GET_TRANSFER_DEAL_COMPLETE, data: response.data });
    yield put(isLoadingAction(false));
  } catch (error) {
    const errorResponse = error.response;

    yield put(
        snackBarAction({
            open: true,
            msg: 'error Load List: ' + errorResponse.data.message,
            type: 'error',
        })
    );
  }
}

function* updateTransferDeal ({ payload }) {
  try {
    yield put(isLoadingAction(true));
    const response = yield call(apiCall, UPDATE_TRANSFER_DEAL, payload.data, yield select(token), 'POST');
    yield put(isLoadingAction(false));
    yield call(getDeals,payload.date);
    yield put(
      snackBarAction({
          open: true,
          msg: response.data.message,
          type: 'success',
      })
  );
  } catch (error) {
    const errorResponse = error.response;

    yield put(
        snackBarAction({
            open: true,
            msg: 'error Load List: ' + errorResponse.data.message,
            type: 'error',
        })
    );
  }
}

export default function* transferDealWatch() {
  yield takeLatest(GET_DEAL_START, getTransferDeals);
  yield takeLatest(EDIT_TRANSFER_DEAL_START, updateTransferDeal);
  yield takeLatest(GET_TRANSFER_DEAL_START, getDeals);
}

