import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

const View = (props) => {
    const {
        labelName,
        nameSelect,
        inputLabelRef,
        labelWidthRef,
        valueProp,
        onChangeProp,
        objectList,
        keyValue,
        keyLabel,
        disableValue,
        getLabelOption,
    } = props;

    return (
        <FormControl variant="outlined" size="small" fullWidth disabled={disableValue}>
            <InputLabel id={`label-${labelName}`} ref={inputLabelRef}>
                {labelName}
            </InputLabel>
            <Select
                labelId={`label-${labelName}`}
                name={nameSelect}
                labelWidth={labelWidthRef}
                value={valueProp}
                onChange={onChangeProp}
            >
                {objectList?.map((obj, i) => {
                    return (
                        <MenuItem value={get(obj, `${keyValue}`)} key={i}>
                            {getLabelOption ? getLabelOption(obj) : get(obj, `${keyLabel}`)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

View.propTypes = {
    labelName: PropTypes.string.isRequired,
    nameSelect: PropTypes.string.isRequired,
    inputLabelRef: PropTypes.object.isRequired,
    labelWidthRef: PropTypes.number.isRequired,
    valueProp: PropTypes.string.isRequired,
    onChangeProp: PropTypes.func.isRequired,
    objectList: PropTypes.array.isRequired,
    keyValue: PropTypes.string.isRequired,
    keyLabel: PropTypes.string.isRequired,
    disableValue: PropTypes.bool,
};
View.defaultProps = {
    disableValue: false,
};

export default View;
