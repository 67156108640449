import {
    PAYPLAN_UPDATE_START,
    PAYPLAN_CREATE_START,
    PAYPLAN_DELETE_START,
    PAYPLAN_LIST_START,
} from '../../consts/actionTypes';

export const listDataAction = () => ({
    type: PAYPLAN_LIST_START,
});

export const upDateDataAction = (id, data) => ({
    type: PAYPLAN_UPDATE_START,
    id,
    data,
});
export const createDataAction = (data) => ({
    type: PAYPLAN_CREATE_START,
    data,
});
export const deleteDataAction = (id) => ({
    type: PAYPLAN_DELETE_START,
    id,
});
