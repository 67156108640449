import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from "@material-ui/core";
import "./styles.css";
import ReactToPrint from "react-to-print";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { useStyles } from "./styles";
import { dollar } from "../../../../consts/dollarFormat";

const Index = props => {
  const { data, tbType, hideColumnInFIM } = props;
  const refDivReportTotals = useRef();
  const classes = useStyles();

  console.log("[rerender] reportTotal");

  return (
    <div>
      <div ref={refDivReportTotals}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead className="report-header">
              <TableRow className="row-logo">
                <TableCell colspan="5">
                  <div align="center" className="div-row-logo">
                    <img
                      height="50px"
                      src="/images/logos/logoUpturnPrint.png"
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                {(tbType === "payout" &&
                  <React.Fragment>
                    <TableCell>Associate </TableCell>
                    {hideColumnInFIM !== "FIM" &&
                      <TableCell align="right">New Car GP Front End</TableCell>}
                    <TableCell align="right">New Car Payout</TableCell>
                    <TableCell align="right">New Car GP Back End</TableCell>
                    {hideColumnInFIM !== "FIM" &&
                      <TableCell align="right">
                        Used Car GP Front End
                      </TableCell>}
                    <TableCell align="right">Used Car Payout</TableCell>
                    <TableCell align="right">Used Car GP Back End</TableCell>
                  </React.Fragment>) ||
                  <React.Fragment>
                    <TableCell>Associate </TableCell>
                    <TableCell align="right">Increase</TableCell>
                    <TableCell align="right">Gp</TableCell>
                    <TableCell align="right">New</TableCell>
                    <TableCell align="right">Used</TableCell>
                    <TableCell align="right">Adjustments</TableCell>
                    <TableCell align="right">Adjustments New</TableCell>
                    <TableCell align="right">Adjustments Used</TableCell>
                    <TableCell align="right">Adjustments Others</TableCell>
                    <TableCell align="right">Draws</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </React.Fragment>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(tbType === "payout" &&
                data.map((row, index) =>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.assoc}
                    </TableCell>
                    {hideColumnInFIM !== "FIM" &&
                      <TableCell align="right">
                        {dollar(row.total_new_cars_gp_front_end)}
                      </TableCell>}
                    <TableCell align="right">
                      {dollar(row.total_new_cars_payout)}
                    </TableCell>
                    <TableCell align="right">
                      {dollar(row.total_new_cars_gp_back_end)}
                    </TableCell>
                    {hideColumnInFIM !== "FIM" &&
                      <TableCell align="right">
                        {dollar(row.total_used_cars_gp_front_end)}
                      </TableCell>}
                    <TableCell align="right">
                      {dollar(row.total_used_cars_payout)}
                    </TableCell>
                    <TableCell align="right">
                      {dollar(row.total_used_cars_gp_back_end)}
                    </TableCell>
                  </TableRow>
                )) ||
                data.map((row, index) =>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.assoc}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_increase}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_gp}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_new_cars}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_used_cars}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_adjustments}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_adjustments_new}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_adjustments_used}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_adjustments_no_new_used}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_draws}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_assoc}
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ReactToPrint
        trigger={() =>
          <Fab size="small" className={classes.fabPrint}>
            <PrintOutlinedIcon fontSize="small" />
          </Fab>}
        content={() => refDivReportTotals.current}
      />
    </div>
  );
};
Index.propTypes = {
  data: PropTypes.array.isRequired
};
Index.defaultProps = {};

const areEqual = (prevProps, nextProps) => {
    return prevProps.data?.assoc === nextProps.data?.assoc
}

export default React.memo(Index, areEqual);
