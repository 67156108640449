import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Fab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
} from '@material-ui/core';
import './styles.css';
import { useStyles } from './styles';
import ReactToPrint from 'react-to-print';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { dollar } from '../../../../consts/dollarFormat';

const ReportTMs = (props) => {
    const { data, infoSearch } = props;
    const classes = useStyles();
    const refDivReportTotals = useRef();
    const formatNumber = (num) => {
        return num % 1 === 0 ? num.toString() : num.toFixed(1);
    };

    return (
        <div>
            <div className="main" ref={refDivReportTotals}>
                <style type="text/css" media="print">
                    {
                        ' @page { size: portrait; margin-top: 5px;margin-bottom: 50px;margin-left: 10px; margin-right: 10px;padding-top: 50px; } '
                    }
                </style>
                <table className="report-container">
                    <thead className="report-header">
                        <tr>
                            <th className="report-header-cell">
                                <div className="header-info">
                                    <div className="pageHeader" align="center">
                                        <img
                                            height="50px"
                                            src="/images/logos/logoUpturnPrint.png"
                                        />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tfoot className="report-footer">
                        <tr>
                            <td className="report-footer-cell">
                                <div className="footer-info"></div>
                            </td>
                        </tr>
                    </tfoot>
                    <tbody className="report-content">
                        <tr>
                            <td className="report-content-cell ">
                                {data?.report?.map((object, index) => (
                                    <div className="page-content-inside-auto container-table-1">
                                        <div className="page-content-inside-auto container-table-1">
                                            <Typography variant="h4">{`${infoSearch.month}${infoSearch.year}-${object?.tm?.dms_number}-${object?.tm?.type}-${object?.tm?.name}`}</Typography>
                                        </div>
                                        <TableContainer className="space-between-table">
                                            <Table stickyHeader size="small">
                                                <TableHead className="report-header">
                                                    <TableRow>
                                                        <TableCell
                                                            width="20px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            #
                                                        </TableCell>
                                                        <TableCell
                                                            width="180px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            Salesperson
                                                        </TableCell>
                                                        <TableCell
                                                            width="40px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            New
                                                        </TableCell>
                                                        <TableCell
                                                            width="40px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            Used
                                                        </TableCell>
                                                        <TableCell
                                                            width="40px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            Unit
                                                        </TableCell>
                                                        <TableCell
                                                            width="120px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            Front Gross
                                                        </TableCell>
                                                        <TableCell
                                                            width="120px"
                                                            size="small"
                                                            align="center"
                                                        >
                                                            Back Gross
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {object?.data?.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell size="small" align="center">
                                                                {index + 1}
                                                            </TableCell>
                                                            {index === object?.data?.length - 1 ? (
                                                                <TableCell
                                                                    align="left"
                                                                    size="small"
                                                                    className={classes.alignLeft}
                                                                    style={{ fontWeight: 'bold' }}
                                                                >
                                                                    {row.associate_name}
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    align="left"
                                                                    size="small"
                                                                    className={classes.alignLeft}
                                                                >
                                                                    {row.associate_name}
                                                                </TableCell>
                                                            )}

                                                            <TableCell size="small">
                                                                {row.total_new_units
                                                                    ? formatNumber(Number(row.total_new_units))
                                                                    : '0'}
                                                            </TableCell>
                                                            <TableCell size="small">
                                                                {row.total_used_units
                                                                    ? formatNumber(Number(row.total_used_units))
                                                                    : '0'}
                                                            </TableCell>
                                                            <TableCell size="small">
                                                                {row.total_units
                                                                    ? formatNumber(Number(row.total_units))
                                                                    : '0'}
                                                            </TableCell>
                                                            <TableCell
                                                                className={classes.alignRight}
                                                                size="small"
                                                                align={'right'}
                                                            >
                                                                {row.total_front
                                                                    ? `$${Number(
                                                                          String(
                                                                              row.total_front
                                                                          ).replace(
                                                                              /[^0-9.-]+/g,
                                                                              ''
                                                                          )
                                                                      ).toLocaleString('en-US', {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                      })}`
                                                                    : '$0.00'}
                                                            </TableCell>
                                                            <TableCell
                                                                size="small"
                                                                className={classes.alignRight}
                                                                align={'right'}
                                                            >
                                                                {row.total_back
                                                                    ? `$${Number(
                                                                          String(
                                                                              row.total_back
                                                                          ).replace(
                                                                              /[^0-9.-]+/g,
                                                                              ''
                                                                          )
                                                                      ).toLocaleString('en-US', {
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                      })}`
                                                                    : '$0.00'}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ))}
                                <>
                                    <div className="page-content-inside-auto container-table-3">
                                        <TableContainer>
                                            <Table stickyHeader size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                            size="small"
                                                            align="center"
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            TOTAL MONTH
                                                        </TableCell>
                                                        <TableCell
                                                            size="small"
                                                            align="center"
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            VALUE
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={Math.random()}>
                                                        <TableCell size="small" align="center">
                                                            {'New'}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {data?.totals_report?.report_total_new !== undefined
                                                                ? formatNumber(Number(data.totals_report.report_total_new))
                                                                : '0'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={Math.random()}>
                                                        <TableCell size="small" align="center">
                                                            {'Used'}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {data?.totals_report?.report_total_used !== undefined
                                                                ? formatNumber(Number(data.totals_report.report_total_used))
                                                                : '0'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={Math.random()}>
                                                        <TableCell size="small" align="center">
                                                            {'Total Units'}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {data?.totals_report?.report_total_unit !== undefined
                                                                ? formatNumber(Number(data.totals_report.report_total_unit))
                                                                : '0'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={Math.random()}>
                                                        <TableCell size="small" align="center">
                                                            {'Total Front Gross'}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {dollar(
                                                                (
                                                                    Number(
                                                                        data?.totals_report
                                                                            ?.report_total_front
                                                                    ) || 0
                                                                ).toFixed(2)
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={Math.random()}>
                                                        <TableCell size="small" align="center">
                                                            {'Total Back Gross'}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">
                                                            {dollar(
                                                                (
                                                                    Number(
                                                                        data?.totals_report
                                                                            ?.report_total_back
                                                                    ) || 0
                                                                ).toFixed(2)
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ReactToPrint
                trigger={() => (
                    <Fab size="small" className={classes.fabPrint}>
                        <PrintOutlinedIcon fontSize="small" />
                    </Fab>
                )}
                content={() => refDivReportTotals.current}
            />
        </div>
    );
};

ReportTMs.propTypes = {
    data: PropTypes.object.isRequired,
};
ReportTMs.defaultProps = {
    data: {},
};

export default ReportTMs;
