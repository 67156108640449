import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    TERM_LIST_START,
    TERM_LIST_COMPLETE,
    TERM_LIST_ERROR,
    TERM_CREATE_START,
    TERM_CREATE_COMPLETE,
    TERM_UPDATE_START,
    TERM_UPDATE_COMPLETE,
    TERM_UPDATE_ERROR,
    TERM_DELETE_START,
    TERM_DELETE_COMPLETE,
    TERM_DELETE_ERROR,
    TERM_SEARCH_START,
    TERM_SEARCH_COMPLETE,
    TERM_SEARCH_ERROR,
    TERM_COMPLETE_REQUEST,
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { TERM, TERM_TERM } from '../../consts/urls';
import { token, idItemSelectedSelector, associateSelector } from '../selectors';

// export function* list() {
//     try {
//         const responseToken = yield select(token);
//         const results = yield call(apiCall, TERM, null, responseToken, 'GET');
//         yield put({ type: TERM_LIST_COMPLETE, results });
//     } catch (error) {
//         const errorResponse = error.response;
//         yield put({ type: TERM_LIST_ERROR, errorResponse });
//         yield put(
//             snackBarAction({
//                 open: true,
//                 msg: 'error Load List: ' + errorResponse.data.message,
//                 type: 'error',
//             })
//         );
//     }
//     yield put(isLoadingAction(false));
// }

export function* create({ data }) {
    try {
        const responseToken = yield select(token);

        const results = yield call(apiCall, TERM, data, responseToken, 'POST');
        yield put({ type: TERM_COMPLETE_REQUEST, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}

export function* update({ id, data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${TERM}/${id}`, data, responseToken, 'PUT');
        yield put({ type: TERM_COMPLETE_REQUEST, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export function* deleteR({ id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${TERM}/${id}`, null, responseToken, 'DELETE');
        yield put({ type: TERM_COMPLETE_REQUEST, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* search({ id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${TERM}/${id}`, null, responseToken, 'GET');
        yield put({ type: TERM_SEARCH_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}

export default function* term() {
    // yield takeLatest(TERM_LIST_START, list);
    yield takeLatest(TERM_CREATE_START, create);
    yield takeLatest(TERM_UPDATE_START, update);
    yield takeLatest(TERM_DELETE_START, deleteR);
    yield takeLatest(TERM_SEARCH_START, search);
}
