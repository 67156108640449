const isBrowser = typeof window !== 'undefined';

export const getLocalStorage = (name = ACCESS_TOKEN) => {
    return isBrowser && window.sessionStorage.getItem(name);
};

export const setLocalStorage = (name = ACCESS_TOKEN, value) => {
    return isBrowser && window.sessionStorage.setItem(name, value);
};

export const rmLocalStorage = (name = ACCESS_TOKEN) => {
    return isBrowser && window.sessionStorage.removeItem(name);
};

export const ACCESS_TOKEN = 'upturn_access_token';
export const REFRESH_TOKEN = 'upturn_refresh_token';
