import React, { useState, useRef, useMemo } from 'react';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import { DinamicTable } from '../../../../components';
import { Dialog, Fab, Box, Tooltip } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ReactToPrint from 'react-to-print';
import { SearchDealByNumber } from '..';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

const Index = (props) => {
    const { orderData } = props;
    const refDivPrintDeal = useRef();
    const refBoxPrintAll = useRef();
    const classes = useStyles();

    const [confDialog, setConfDialog] = useState({
        open: false,
        type: '',
        indexArray: 0,
    });
    const onCloseDialogFullScreen = (x) => {
        setConfDialog({
            ...confDialog,
            open: x,
        });
    };

    const handleDialogFullScreen = (data) => {
        setConfDialog({
            open: true,
            indexArray: data.tableData.id,
        });
    };
    const AllPrint = useMemo(() => {
        return (
            <Box display="none">
                <div ref={refBoxPrintAll}>
                    {orderData.map((obj, index) => {
                        return (
                            <div style={{ marginTop: 5 }}>
                                <SearchDealByNumber data={orderData[index]} />
                            </div>
                        );
                    })}
                </div>
            </Box>
        );
    }, [orderData]);

    const ViewInDiolog = () => {
        switch (confDialog.type) {
            case 'single':
                return <SearchDealByNumber data={orderData[confDialog.indexArray]} />;
            case 'all':
                return orderData.map((obj, index) => {
                    return (
                        <div style={{ marginTop: 5 }}>
                            <SearchDealByNumber data={orderData[index]} />
                        </div>
                    );
                });
            default:
                return <div />;
        }
    };
    return (
        <div>
            <DinamicTable
                reducer={'searchReducer'}
                object={'dataTable'}
                isGrouping={true}
                editable={false}
                expandInfo={true}
                handleDialogFullScreen={handleDialogFullScreen}
            />
            {AllPrint}
            <ReactToPrint
                trigger={() => (
                    <Tooltip title="Print All">
                        <Fab size="small" className={classes.fabPrint}>
                            <PrintOutlinedIcon fontSize="small" />
                        </Fab>
                    </Tooltip>
                )}
                content={() => refBoxPrintAll.current}
            />
            <Dialog
                fullScreen
                open={confDialog.open}
                onClose={() => onCloseDialogFullScreen(false)}
            >
                <Fab className={classes.fabClose} onClick={() => onCloseDialogFullScreen(false)}>
                    <CancelOutlinedIcon fontSize="large" />
                </Fab>
                <ReactToPrint
                    trigger={() => (
                        <Fab className={classes.fabPrint}>
                            <PrintOutlinedIcon fontSize="large" />
                        </Fab>
                    )}
                    content={() => refDivPrintDeal.current}
                />
                <div ref={refDivPrintDeal}>
                    <SearchDealByNumber data={orderData[confDialog.indexArray]} />
                </div>
            </Dialog>
        </div>
    );
};
Index.propTypes = {
    orderData: PropTypes.array.isRequired,
};
Index.defaultProps = {};

export default Index;
