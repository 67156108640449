import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button, IconButton } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import { useStyles } from './styles';
import { Autocomplete } from '../../../../components';

const Index = (props) => {
    const classes = useStyles();
    const { data, onSubmit, isLoading, addButton } = props;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={2}>
                {Object.keys(data).map((element, index) => {
                    return (
                        <Grid key={index} item xs={3}>
                            <Autocomplete
                                listOptions={data[element]?.options || []}
                                onChange={data[element].onChange}
                                label={data[element].label}
                            />
                        </Grid>
                    );
                })}
                <Grid item xs={2}>
                    {addButton && (
                        <IconButton onClick={onSubmit}>
                            <AddCircleRoundedIcon fontSize="large" color="secondary" />
                        </IconButton>
                    )}
                    {!addButton && (
                        <Button color="primary" fullWidth variant="contained" onClick={onSubmit}>
                            {isLoading ? 'loading..' : 'View'}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

Index.propTypes = {
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    addButton: PropTypes.bool.isRequired,
};

Index.defaultProps = {
    addButton: false,
};

export default Index;
