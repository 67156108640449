import React from 'react';
import { useStyles } from './styles';
import { Typography, Button } from '@material-ui/core';

export default (props) => {
    const { goToBack } = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.quote}>
                <div className={classes.quoteInner}>
                    <img src="/images/logos/logoUpturn.png" alt="logoUpTurn" />
                    <Typography className={classes.quoteText} variant="h1">
                        Page in Construction
                    </Typography>
                    <Button color="secondary" variant="contained" onClick={() => goToBack()}>
                        Go to Back
                    </Button>
                </div>
            </div>
        </div>
    );
};
