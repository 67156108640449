import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch } from 'react-redux';
import { logOutAction } from '../../../../redux/actions/loginAction';

const Topbar = (props) => {
    const { className, onSidebarOpen, visibleMenuIcon, ...rest } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const onLogout = () => {
        dispatch(logOutAction());
    };

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <Toolbar>
                <RouterLink to="/commission_pages">
                    <img src="/images/logos/logoPequeñoUpturn.png" />
                </RouterLink>
                <div className={classes.flexGrow} />

                <IconButton className={classes.signOutButton} color="inherit" onClick={onLogout}>
                    <InputIcon />
                </IconButton>

                {visibleMenuIcon ? (
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <div />
                )}
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

export default Topbar;
